.myOrders {
  &__card {
    box-shadow: rgba(0, 0, 0, 0.12) 0 4px 16px;
  }

  &__cardContent {
    cursor: pointer;
  }

  &__emptyContent {
    @media (min-width: 767.98px) {
      height: 70vh;
    }
  }
  &Product__label {
    font-size: 12px;
  }
}

.pending {
  background-color: var(--ion-color-primary);
}
.cancel {
  background-color: red;
}
.complete {
  background-color: var(--ion-color-medium);
}
.accept {
  background-color: var(--ion-color-success);
  cursor: pointer;

  &:hover {
    background-color: var(--ion-color-success-tint);
  }
  &:active {
    background-color: var(--ion-color-success-tint);
  }
}

.inProcess {
  background-color: var(--ion-color-primary);
}

.orderStatusBar {
  border-radius: 22px;
  padding: 5px 9px;
  color: white;
  position: relative;
  left: 9px;
}

.empty {
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &__content {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  &__icon {
    font-size: 62px;
  }
}
