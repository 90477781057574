.container {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;
}

.noInternetConnection {
  height: 50%;
  background: url('../../assets/images/noConnection.png') no-repeat bottom;
  background-size: 200px;
  margin-bottom: 25px;
}

.noBluetoothConnection {
  height: 50%;
  background: url('../../assets/images/bluetooth.svg') no-repeat bottom;
  background-size: 100px;
  opacity: 45%;
  margin-bottom: 25px;
}

.message {
  margin: 0 20px;
  color: var(--ion-color-primary);
}

.ionGrid {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.button {
  height: 51px;
  text-transform: none;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: unset;
  color: #fff;
  --border-radius: 100px;
}

.buttonClear {
  font-weight: 600;
}

.geolocationButtonRow {
  height: 40%;
  align-items: flex-end;
}
