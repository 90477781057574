.myOrders {
  &__card {
    margin: 10px;
    box-shadow: rgba(0, 0, 0, 0.12) 0 4px 16px;
  }

  &__cardContent {
    cursor: pointer;
    padding: 10px;
  }

  &__emptyContent {
    @media (min-width: 767.98px) {
      height: 70vh;
    }
  }
  &Product__label {
    font-size: 12px;
  }
}



.orderStatus {
  border-radius: 50%;
  width: 22px;
  height: 22px;
  color: white;
  text-align: center;

  &__container{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &--Processing {
    background-color: var(--ion-color-primary);
  }

  &--Pending {
    background-color: #428cff;
  }
  &--Canceled {
    background-color: var(--ion-color-medium);
  }
  &--Completed {
    background-color: var(--ion-color-medium);
  }
}

.order{
  &__id{
    font-size: 16px;
    font-weight: bolder;
  }
  &__descr{
    display: flex;
    align-items: center;
  }
  &__icon{
    font-size: 23px;
    padding-right: 10px;
  }
  &__span{
    padding-top: 4px;
  }
  &__fullName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
