.ionGrid {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.halfHeight {
  flex-grow: 1;
}

.inputFieldsContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 5px;
}

.button {
  height: 51px;
  text-transform: none;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: unset;
  color: #fff;
  --border-radius: 100px;
}

.textRow {
  padding-top: 10%;
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  align-items: flex-end;
  text-align: center;
  color: #333333;

  div {
    width: 80%;
    margin: 0 auto;
  }
}

.inputRow {
  justify-content: center;
  align-items: flex-end;
  flex-wrap: wrap;
}

.inputItem {
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  width: 100%;

  &Phone {
    --padding-start: 10px;
  }
}

.input {
  width: 100%;
  border: none;
  outline: none;
}

.toast {
  text-align: center;
}

.dangerField {
  border-color: var(--ion-color-danger);
}
