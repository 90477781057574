ion-content ion-toolbar {
}

.pageHeader {
}

.toolbar {
}

.toolbarReverseColor {
  margin-bottom: -1px;
  color: white;
}

.toolbar:last-child {
}

.pageTitle {
}

.searchbar {
}

.paginationItem {
}

.paginationIcon {
}

.dataPicker {
}

.dataPickerInfo {
}

.dataPickerData {
}

.datatimeField {
}

.fab {
}

.header {
}
