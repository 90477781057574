ion-modal#moneyChange__modal ion-toolbar {
  --background: var(--ion-color-primary);
  --color: var(--ion-color-secondary-contrast);
}
ion-modal#moneyChange__modal {
  --width: 80%;
  --min-width: 250px;
  --height: fit-content;
  --border-radius: 10px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

ion-modal#moneyChange__modal ion-icon {
  color: var(--ion-color-dark);
}
