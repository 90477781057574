.wrapper {
  padding: 0 15px;
}

.item {
  margin-bottom: 15px;

  &::part(native) {
    padding-left: 0;
  }
}

.button {
  margin-bottom: 15px;

  &::part(native) {
    color:  var(--ion-color-dark);
  }
}

.title {
  padding-inline: 40px;
}

.error {
  color: var(--ion-color-danger);
  font-size: 17px;
}