

.card {
  box-shadow: rgba(0, 0, 0, 0.12) 0 4px 16px;

  &__content {
    cursor: pointer;
  }
}

.status {
  border-radius: 50%;
  width: 22px;
  height: 22px;

  &__container{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &--Processing, &--On__delivery {
    background-color: var(--ion-color-primary);
  }

  &--Pending, &--Waiting__delivery {
    background-color: #428cff;
  }
  &--Canceled, &--Empty {
    background-color: var(--ion-color-medium);
  }
  &--Completed {
    background-color: var(--ion-color-medium);
  }
}