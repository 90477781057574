.order-details {
  color: black;
  background: white;
  border-radius: 10px;

  &-header {
    text-transform: none;
    padding: 15px 30px;
    color: black;
  }
  &-title {
    text-transform: none;
    // padding: 0 12px;
    background: none;
    font-size: 16px;
    font-weight: 500;
    color: black;
  }

  &-subtitle {
    text-transform: none;
    font-weight: 500;
    font-size: 14px;
    padding: 10px 30px 4px 30px;
    background: #e9e9e9;
    color: black;
  }
  &-description {
    text-transform: none;
    font-size: 12px !important;
    font-weight: lighter;
    background: rgba($color: white, $alpha: 0.7);
    padding: 8px 15px 20px 30px;
    color: black;
    ion-col {
      padding: 4px 0;
    }
  }

  &-content {
    font-weight: 400;
    background: transparent;
    padding: 0;
    font-size: 12px !important;
  }

  &-note {
    padding: 0;
  }
}

.products-wrapper {
  ion-card-content {
    padding: 0;
  }
}

.my-order-detail {
  ion-col {
    font-size: 12px;
  }
}

.showRateDriverModal_container {
  transition: max-height 0.3s;
  max-height: 0;

  &__expanded {
    max-height: 59px;
  }
}
.product__title {
  padding-left: 15px;
  padding-right: 15px;
  font-size: 16px;
}

.detail__label {
  font-weight: bold;
}

.detail__descr {
  padding-bottom: 0;
  margin-bottom: 2px;
}

.order__productsSpan {
  font-size: 14px !important;
}

.order-detail-map {
  height: 400px;
  margin-top: 0;
}

.open-map {
  white-space: break-spaces;
}

.order-note {
  color: orangered;
  text-transform: uppercase;
}

.order-note,
.total-fontSize {
  font-size: 14px !important;
}

.order{
  &__button {
    margin-right: 10px;
    margin-left: 10px;
  }

  &-products__title {
    font-size: 16px !important;
    padding-left: 15px;
  }

  &-products__header {
    padding: 0;
    background-color: var(--ion-color-primary);
  }
}

.button__row {
  padding-top: 15px;
}
