ion-content ion-toolbar {
  --background: transparent;
}

.pageHeader {
  color: white;
}

.toolbar {
  padding: 20px 16px;
  margin-bottom: -1px;
  --min-height: auto;
  --border-style: none;
}

.toolbarReverseColor {
  padding: 20px 16px;
  margin-bottom: -1px;
  --min-height: auto;
  --border-style: none;
  color: white;
}

.toolbar:last-child {
  margin-bottom: 0;
}

.pageTitle {
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  text-align: center;
  padding-inline: 40px;
  // display: inherit; - дисплей контент, чтобы не исчезало когда высоты нет
}

.searchbar {
  height: 50px;
  font-size: 16px;
  color: #464646;
  background: #f6f6f6;
  border: 1px solid #e8e8e8;
  border-radius: 100px;
  --padding-start: 16px;
}

.fab {
  width: 39px;
  height: 39px;
  --border-radius: 5px !important;
}

.header {
  padding-right: 85px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: white;
}
