.row {
  width: 100%;
  padding: 10px 0;
}

.separator {
  max-width: 30px;
}

.checkbox {
  margin: 0;
  margin-left: auto;
}