.emptyPage {
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &__content{
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__icon{
    font-size: 62px;
  }
}