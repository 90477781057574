.cardTitle {
  font-weight: bold;
  font-size: 4.5vw;
  margin: 6px 10px;
  color: black;
  word-wrap: normal;
  height: 100px;
}

@media screen and (min-width: 550px) {
  .cardTitle {
    font-size: 18px;
  }
}

.cardHeader {
  text-align: right;
}

.iconBckg {
  display: inline-block;
  padding: 12px;
  background: white;
  border-radius: 50%;
}

.ionCard {
  margin: 4px;
  box-shadow: none;
}

.cardContentBlock {
  min-height: 100px;
  height: 100px;
}

.cardDescription {
  margin: 4px 10px;
  opacity: 0.4;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.lightPinkBckg {
  background-color: #ffeaea;
}

.lightGreen2Bckg {
  background-color: #e0efd8;
}

.lightPurpleBckg {
  background-color: #ede4ff;
}

.lightGreenBckg {
  background-color: #e3ffef;
}

.lightCyanBckg {
  background-color: #e3eeff;
}

.lightYellowBckg {
  background-color: #f2f8de;
}

.cornSilkBckg {
  background-color: #fffcd9;
}

.harmoniesBckg {
  background-color: #fce3cf;
}

.dkGreenBckg {
  background-color: #e4efc6;
}

.dkPurpleBckg {
  background-color: #dfd3e7;
}

.lgVioletBckg {
  background-color: #d7cfff;
}

.lgCyanBckg {
  background-color: #bde7ea;
}

.lgPinkBckg {
  background-color: #fbdbe8;
}

.lgBlueBckg {
  background-color: #d1edfb;
}

.pink {
  color: #ff0200;
}

.lgGreen {
  color: #76aa5b;
}

.purple {
  color: #7230de;
}

.green {
  color: #3fd28c;
}

.cyan {
  color: #005bff;
}

.turmeric {
  color: #a79a3e;
}

.antiquebrass {
  color: #cc9165;
}

.linkButton {
  text-decoration: none;
}
