.purpleBackground {
  --background: linear-gradient(var(--ion-color-primary) 50%, white 50%);
}

.menuContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;
}

.menuHeader {
  display: flex;
  height: 9.375rem;
  padding: 1rem;
  align-items: center;
  gap: 1rem;
  font-size: 16px;
  background-color: var(--ion-color-primary);
}


.headerImg {
  display: block;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  cursor: pointer;
  object-fit: cover;
}

.driverData {
  display: flex;
  flex-direction: column;
  gap: 6px;
  font-family: 'SF Pro Display';
  font-weight: 600;
  color: #fff;
  cursor: pointer;
}

.username {
  //flex: 1;
  font-family: 'SF Pro Display';
  font-weight: 600;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
  //margin-bottom: 0.5rem;
}

.rating {
  display: flex;
  align-items: center;
  gap: 6px;
}

.star {
  font-size: 1.2em;
  padding-bottom: 0.313rem;
}

.rate {

}

.connectionStatusContainer {
  justify-content: center;
  padding-top: 10px;

  ion-icon {
    align-self: center;
    position: relative;

    &.not-connected::after {
      content: '';
      width: 1px;
      height: 18px;
      background-color: white;
      position: absolute;
      top: -3px;
      left: 6px;
      transform: skew(50deg, 116deg);
    }
  }
}

.connectionStatus {
  @extend .username;
  flex: 0;
  margin-left: 8px;
  font-size: 12px;
  line-height: 15px;
  cursor: unset;
}

.icon {
  margin-right: auto;
  width: 22px;
  height: 17px;
  padding-top: 10px;
}

.grid {
  align-self: center;
}

.pageButton {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  color: #404040;
  cursor: pointer;
}

.lastElement {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  color: var(--ion-color-primary);
}


.appVersion {
  font-size: 14px;
  color: #a4a4a4;
  padding-left: 10px;
}
