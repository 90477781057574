.statsCard {
  margin: 16px;
}

.cardRow {
  align-items: center;
}

.cardIcon {
  font-size: 25px;
  font-weight: bold;
}